<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$ADMINISTRADOR')
                  ? `/proyectos/cns/administracion`
                  : `/proyectos/cns/externo/registro-informacion`
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            Enmiendas de Componentes
            <v-skeleton-loader
              v-if="skeletonLoading"
              min-height="50px"
              type="list-item"
            ></v-skeleton-loader>
            <v-subheader
              class="pl-0 font-weight-black text-h5 text--blue-grey text--lighten-4"
              v-if="!skeletonLoading"
            >
              {{ codigoProyecto }}
            </v-subheader>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color="light-blue-502"
              dark
              class="mb-2 float-right white--text"
              @click="modalNuevo"
            >
              <v-icon left>mdi-plus</v-icon>
              Nueva enmienda
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="enmiendas"
          :loading="tableLoading"
          :search="filtro"
          no-data-text="No se encontraron registros"
          no-results-text="No se encontraron registros con el filtro especificado"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Número de enmienda, descripción..."
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="estadoSeleccionado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.orden }}</td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  small
                  :color="
                    item.procedencia === 'SIGEACI'
                      ? 'blue-grey lighten-4'
                      : 'blue lighten-4'
                  "
                  :text-color="
                    item.procedencia === 'SIGEACI'
                      ? 'blue-grey lighten-1'
                      : 'blue lighten-1'
                  "
                >
                  {{ item.procedencia }}
                </v-chip>
              </td>
              <td>{{ item.codigo }}</td>
              <td>
                {{ item.fechaCreacion | formatDate }}
              </td>

              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'red lighten-4'
                      : item.estadosId === 4
                      ? 'cyan lighten-4'
                      : item.estadosId === 3
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'red lighten-1'
                      : item.estadosId === 4
                      ? 'cyan darken-1'
                      : item.estadosId === 3
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadosId === 1 ? `En registo` : item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  v-if="
                    (item.estadosId === 1 || item.estadosId === 3 || item.estadosId === 4) &&
                      item.procedencia == 'SICOOPERA'
                  "
                  class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id, 2)"
                >
                  <v-icon left>{{
                    item.estadosId === 1 ? `mdi-pencil` : `mdi-eye`
                  }}</v-icon>
                  {{ item.estadosId === 1 ? `Actualizar` : `Visualizar` }}
                </v-btn>

                <v-btn
                  v-if="
                    (item.estadosId === 3 || item.estadosId === 1) &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                        ma-2
                        btn-bg-light  
                        blue-grey--text
                        lighten-2--text
                        font-weight-medium
                        text-capitalize
                    "
                  @click="mostrarModalInactivar(item.id, 2)"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-tab-remove</v-icon>
                  Inactivar enmienda
                </v-btn>

                <v-btn
                  v-if="
                    item.estadosId === 2 &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                            ma-2
                            btn-bg-light  
                            blue-grey--text
                            lighten-2--text
                            font-weight-medium
                            text-capitalize
                        "
                  @click="mostrarModalInactivar(item.id, 1)"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-check-outline</v-icon>
                  Activar enmienda
                </v-btn>

                <template v-if="item.procedencia == 'SICOOPERA'">
                  <div class="text-center float-left">
                    <v-dialog v-model="dialogAlertaValidacion" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="item.estadosId === 1"
                          color="deep-orange lighten-2"
                          text
                          fab
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title>
                          Alerta
                        </v-card-title>

                        <v-card-text>
                          <p class="text-h6 black--text">
                            Es necesario validar la enmienda para que esta surta
                            efecto
                          </p>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="light-blue-502"
                            class="white--text"
                            @click="dialogAlertaValidacion = false"
                          >
                            Cerrar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
              {{ code4.html }}
            </template>
            <template v-slot:js>
              {{ code4.js }}
            </template> -->
    </v-card>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1280px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Registrar enmienda de componentes | {{ codigoProyecto }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetItems();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-card outlined>
            <v-card-text>
              <v-form
                ref="formDatosIniciales"
                v-on:submit.prevent="registrarDatosIniciales"
                v-model="validFormDatosIniciales"
              >
                <v-row class="mt-4">
                  <v-col cols="12" md="4" sm="4" xs="6" class="pt-0  pb-0 mb-0">
                    <v-text-field
                      autocomplete="off"
                      class="required"
                      dense
                      :disabled="true"
                      filled
                      color="blue-grey lighten-2"
                      v-model="fechaActual"
                      label="Fecha de la enmienda"
                      maxlength="10"
                      v-mask="'##/##/####'"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" sm="4" xs="6" class="pt-0 pb-0">
                    <v-text-field
                      v-if="enmiendaRegistrada"
                      autocomplete="off"
                      class=""
                      dense
                      :disabled="true"
                      filled
                      color="blue-grey lighten-2"
                      v-model="datosEnmienda.codigo"
                      label="Código de la enmienda"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                    xs="12"
                    class="pt-0 pb-0"
                    v-if="!enmiendaRegistrada && !enmiendaValidada"
                  >
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right mt-1"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormDatosIniciales"
                      :loading="btnRegistroEnmienda"
                    >
                      Registrar enmienda
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="mt-6" outlined>
            <v-card-text>
              <!-- inicio:: seccion datos financieros-->
              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-subheader class="text-h6 black--text">
                    Datos financieros del proyecto
                  </v-subheader>
                  <v-divider class="mt-0 pt-1 pb-6"></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <!--inicio:: tabla datos financieros -->
                <v-col cols="12" md="12" sm="12" class="pt-2">
                  <v-data-table
                    outlined
                    dense
                    class="elevation-1"
                    :headers="headersDatosFinancieros"
                    :items="datosFinancierosProyecto"
                    :loading="tableDatosFinancierosLoading"
                    hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreTipoDatoFinanciero }}</td>
                        <td>{{ item.nombreMoneda }}</td>
                        <td class="text-right">
                          {{
                            `${item.codigoMoneda}  ${item.montoMonedaOriginal}`
                          }}
                        </td>
                        <td class="text-right">{{ item.tipoCambio }}</td>
                        <td class="text-right">
                          {{
                            parseFloat(
                              item.montoTotalQuetzales
                            ).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ",
                              maximumFractionDigits: 10,
                            })
                          }}
                        </td>
                        <!-- <td class="text-right"> Q {{ parseFloat(item.montoTotalQuetzales) }}</td> -->
                        <!--  <td>
                                                <v-btn
                                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                    small
                                                    depressed
                                                    :disabled="btnEliminarDisabled"
                                                    color="blue-grey lighten-5"
                                                    @click="eliminarDatoFinanciero(item.id)"
                                                    >
                                                    <v-icon left>mdi-delete</v-icon> Eliminar
                                                </v-btn>
                                            </td> -->
                      </tr>
                    </template>

                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de datos financieros
                      </p>
                    </template>
                  </v-data-table>

                  <v-row
                    class="mt-4"
                    v-if="
                      contraPartidaRegistrada &&
                        otrosAportesRegistrados &&
                        alertaDatosFinancierosMonedaCambio
                    "
                  >
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                      <v-alert outlined type="error" color="red darken-2" dense>
                        Los datos de la contrapartida y otros aportes no
                        coinciden (Revise la moneda y el tipo de cambio de ambos
                        datos financieros)
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-col>
                <!--fin:: tabla datos financieros -->
                <!-- inicio: total de la secion -->

                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="pt-0 pb-0"
                  v-if="datosFinancierosProyecto.length > 0"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Monto Suscrito Fuente Cooperante</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        parseFloat(
                          totalDatosFinancierosFC
                        ).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ",
                          maximumFractionDigits: 10,
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="pt-0 pb-0"
                  v-if="datosFinancierosProyecto.length > 0"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Monto Suscrito Nacional + Otros</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        parseFloat(
                          totalDatosFinancierosCP
                        ).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ",
                          maximumFractionDigits: 10,
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <!--  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0"  v-if="datosFinancierosProyecto.length > 0">
                                <v-list-item two-line>
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-h6">Monto Suscrito Otros</v-list-item-title>
                                        <v-list-item-subtitle class="text-h6">{{parseFloat(totalDatosFinancierosOtros).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 })}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col> -->

                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="pt-0 pb-0"
                  v-if="datosFinancierosProyecto.length > 0"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Monto Suscrito Total</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">{{
                        parseFloat(
                          totalDatosFinancieros
                        ).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ",
                          maximumFractionDigits: 10,
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!--fin:: total de la seccion -->
              </v-row>
            </v-card-text>
          </v-card>
          <!-- fin:: seccion datos financieros-->

          <!-- inicio:: registro de subdivisión componente/objetivo/indicador/resultado/meta -->
          <v-card
            class="mt-6"
            outlined
            v-if="enmiendaRegistrada && !enmiendaValidada"
          >
            <v-card-title>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Registrar Nueva Subdivisión
                  </span>

                  <v-btn
                    class="float-right mx-2"
                    fab
                    dark
                    small
                    color="light-blue-502"
                    @click.stop="dialogAyuda = true"
                  >
                    <v-icon dark>mdi-help-circle-outline</v-icon>
                  </v-btn>

                  <!--<span class="text-muted mt-3 font-weight-bold font-size-md">
                                        (Componente, objetivo, indicador, resultado, meta)
                                    </span>-->
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formProyeccionDesembolsos"
                v-on:submit.prevent="agregarProyeccionSubDivision"
                v-model="validFormProyeccionSubDivision"
              >
                <v-row class="mt-">
                  <v-col cols="12" md="12" sm="12" class="pb-0">
                    <v-subheader class="text-h6 black--text pb-0">
                      Subdivisión del monto suscrito de la fuente cooperante
                    </v-subheader>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                    <v-select
                      v-model="datosEjecucion.actividadesEjecucionId"
                      :items="subDivisiones"
                      :loading="false"
                      dense
                      filled
                      label="Subdivisión"
                      item-text="nombreActividad"
                      item-value="id"
                      :no-data-text="
                        subDivisiones != null
                          ? 'Selecciona una subdivisión'
                          : 'No se han encontrado subdivisiones'
                      "
                      menu-props="auto"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-textarea
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      v-model="datosEjecucion.descripcion"
                      label="Descripción"
                      :rules="[
                        required('descripción de la subdivisión'),
                        maxLength('descripción de la subdivisión', 2500),
                      ]"
                      maxlength="2500"
                      rows="3"
                    ></v-textarea>
                  </v-col>

                  <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                                            <v-select
                                                v-model="aportaContrapartida"
                                                :items="aportaContrapartidaItems"
                                                :loading="false"
                                                dense
                                                :disabled="!contraPartidaRegistrada"
                                                filled 
                                                label="¿Registrar para contrapartida?"
                                                item-text="text"
                                                item-value="id"
                                                :no-data-text="
                                                    aportaContrapartidaItems.length > 0
                                                    ? 'Selecciona una opción'
                                                    : 'No se han encontrado opciones'
                                                "
                                                menu-props="auto"
                                            ></v-select>
                                        </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right"
                      type="submit"
                      :elevation="0"
                      :disabled="
                        !validFormProyeccionSubDivision ||
                          datosFinancierosProyecto.length <= 0 || (datosEjecucion.actividadesEjecucionId == null || datosEjecucion.actividadesEjecucionId == 0)
                      "
                      :loading="btnRegistroEjecucionActividadLoading"
                    >
                      Agregar Subdivisión
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <!--  <v-row class="mt-">
                                
                                <v-col cols="12" md="12" sm="12" class="pb-0">
                                    <v-subheader class="text-h6 black--text pb-0">
                                        Monto suscrito de la fuente cooperante
                                    </v-subheader>
                                </v-col>
                            </v-row> -->

              <v-row>
                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    class="elevation-1"
                    dense
                    :headers="headersSubDivisionesCooperante"
                    :items="proyeccionesSubDivision"
                    :loading="tableLoadingSubdivisionCooperante"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <!-- <td class="text-right">{{ parseFloat(item.aporteFuenteCooperante).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 }) }}</td>
                                                
                                                <td>
                                                    <v-btn
                                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                        small
                                                        depressed
                                                        color="blue-grey lighten-5"
                                                        :disabled="bntEliminarDistribucionCooperanteDisabled"
                                                        @click="eliminarDistribucionCooperante(item.id)">
                                                        <v-icon left>mdi-delete</v-icon> Eliminar
                                                    </v-btn>
                                                </td> -->
                      </tr>
                    </template>

                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de fuente cooperante
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->

                <!-- inicio: total de la secion -->
                <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Fuente Cooperante</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6"
                        >{{
                          parseFloat(
                            totalSubDivisionCooperante
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                        /
                        {{
                          parseFloat(
                            totalDatosFinancierosFC
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col> -->
                <!--fin:: total de la seccion -->
              </v-row>

              <v-row v-if="contraPartidaRegistrada">
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pb-0">
                  <v-subheader class="text-h6 black--text pb-0">
                    Subdivisión del monto suscrito de la contrapartida
                  </v-subheader>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                  <v-form
                    ref="formProyeccionDesembolsos"
                    v-on:submit.prevent="
                      agregarProyeccionSubDivisionContrapartida(0)
                    "
                    v-model="validFormProyeccionSubDivisionContraPartida"
                  >
                    <v-row>
                      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-select
                          v-model="
                            datosEjecucionContraPartida.actividadesEjecucionId
                          "
                          :items="subDivisiones"
                          :loading="false"
                          dense
                          filled
                          label="Subdivisión"
                          item-text="nombreActividad"
                          item-value="id"
                          :no-data-text="
                            subDivisiones != null
                              ? 'Selecciona una subdivisión'
                              : 'No se han encontrado subdivisiones'
                          "
                          menu-props="auto"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-textarea
                          dense
                          filled
                          autocomplete="off"
                          class="required"
                          color="blue-grey lighten-2"
                          v-model="datosEjecucionContraPartida.descripcion"
                          label="Descripción"
                          :rules="[
                            required('descripción de la proyección'),
                            maxLength('descripción de la subdivisión', 2500),
                          ]"
                          maxlength="2000"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="light-blue-502"
                          class="white--text mb-2 float-right"
                          type="submit"
                          :elevation="0"
                          :disabled="
                            !validFormProyeccionSubDivisionContraPartida ||
                              datosFinancierosProyecto.length <= 0
                          "
                          :loading="
                            btnRegistroEjecucionActividadContraPartidaLoading
                          "
                        >
                          Agregar subdivisión
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>

                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA-->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    dense
                    class="elevation-1"
                    :headers="headersSubDivisionesContrapartida"
                    :items="proyeccionesSubDivisionContrapartida"
                    :loading="tableLoadingSubdivisionContrapartida"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <!-- <td class="text-right">{{ parseFloat(item.monto).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 }) }}</td>
                                                <td>
                                                    <v-btn
                                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                        small
                                                        depressed
                                                        :disabled="btnEliminarDistribucionContrapartidaDisabled"
                                                        color="blue-grey lighten-5"
                                                        @click="eliminarDistribucionContrapartida(item.id)"
                                                        >
                                                        <v-icon left>mdi-delete</v-icon> Eliminar
                                                    </v-btn>
                                                </td> -->
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de contrapartida
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA -->

                <!-- inicio: total de la secion -->
                <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Nacional</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">
                        {{
                          parseFloat(
                            totalSubDivisionContrapartida
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                        /
                        {{
                          parseFloat(
                            totalDatosFinancierosCP
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col> -->
                <!--fin:: total de la seccion -->
                <!-- inicio: total de la secion 
                                <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                                    <v-list-item two-line>
                                        <v-list-item-content class="pb-0 pt-0">
                                            <v-list-item-title class="text-h6">Total Desembolsos</v-list-item-title>
                                            <v-list-item-subtitle class="text-h6">{{parseFloat(totalSubDivisiones).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 })}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                fin:: total de la seccion -->
              </v-row>
            </v-card-text>
          </v-card>
          <!-- fin:: registro subdivisión componente/objetivo/indicador/resultado/meta -->

          <v-card
            outlined
            class="mt-4"
            v-if="enmiendaRegistrada || enmiendaValidada"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Reasignación de componentes </span
                  ><br />
                  <span
                    class="gray--text mt-3 font-weight-bold text-subtitle-1"
                  >
                    Subdivisión, Componente, Objetivo, Indicador o Meta
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-2 pb-2"></v-divider>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12" md="12" sm="12" class="pb-2">
                  <v-subheader class="text-h6 black--text pb-0">
                    Subdivisión del monto suscrito de la fuente cooperante
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row v-if="!fuenteCooperanteRegistrada">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert
                    outlined
                    type="warning"
                    color="orange darken-1"
                    dense
                  >
                    No existen datos financieros para la fuente coopoerante
                    registrados.
                  </v-alert>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formReasignacionFC"
                v-on:submit.prevent="registrarReasignacionFC"
                v-model="validFormReasignacionFC"
              >
                <v-row class="mt-4">
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <p class="text-subtitle-1 mb-0">Operación</p>
                    <v-radio-group class="mt-0" v-model="accionFC" row>
                      <v-radio
                        label="Aumentar monto (+)"
                        color="light-blue darken-2"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="Disminuir monto (-)"
                        color="light-blue darken-2"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-select
                      v-model="datosReasignacionFC.ejecucionActividadId"
                      :items="proyeccionesSubDivision"
                      :loading="false"
                      dense
                      filled
                      class="required"
                      label="Subdivisión"
                      item-text="nombreActividad"
                      item-value="id"
                      :no-data-text="
                        proyeccionesSubDivision != null
                          ? 'Selecciona una subdivisión'
                          : 'No se han encontrado subdivisiones'
                      "
                      menu-props="auto"
                      :rules="[selectRequired('Subdivisión')]"
                    >
                      <template v-slot:selection="{ item }" class="pt-2">
                        <v-list-item
                          v-on="on"
                          v-bind="attrs"
                          class="pl-0"
                          style="min-height: 40px !important;"
                        >
                          <v-list-item-content
                            style="padding: 2px 0 !important;"
                          >
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-5 pb-1 pl-0">
                                    <span>{{
                                      item.nombreActividad +
                                        " | " +
                                        item.descripcion
                                    }}</span>
                                  </v-col>
                                  <!--  <v-col cols="12" class="pt-1 pb-1">
                                                                      <span>{{ item.descripcion }}</span>
                                                                  </v-col> -->
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                          <v-chip text-color="white" color="blue-grey darken-1" small>
                                                              {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 })  }}
                                                          </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <!--  {{ item.nombreActividad + ' | ' + item.descripcion  + ' | ' + parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 }) }} -->
                      </template>

                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.nombreActividad }}</span>
                                  </v-col>
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.descripcion }}</span>
                                  </v-col>
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                          <v-chip text-color="white" color="blue-grey darken-1" small>
                                                              {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 })  }}
                                                          </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      :suffix="accionFC == '1' ? `(+) GTQ` : `(-) GTQ`"
                      v-model="montoModificadoCooperante"
                      label="Monto"
                      :rules="[
                        required('monto'),
                        decimals10('monto'),
                        maxLength('monto', 30),
                      ]"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-btn
                      color="light-blue-502"
                      class="mb-2 float-right mt-2 light-blue-502 white--text"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormReasignacionFC"
                      :loading="btnRegistroReasignacionFC"
                    >
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="fuenteCooperanteRegistrada">
                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    class="elevation-1"
                    dense
                    :headers="headersReasignacionesFC"
                    :items="reasignacionesFC"
                    :loading="tableLoadingReasignacionesFC"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td>
                          {{ item.tipoAccion === 1 ? `Aumentar` : `Reducir` }}
                        </td>
                        <td class="text-right">
                          {{
                            parseFloat(
                              item.montoModificado
                            ).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ",
                              maximumFractionDigits: 10,
                            })
                          }}
                        </td>

                        <td>
                          <v-btn
                            v-if="!enmiendaValidada"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            color="blue-grey lighten-5"
                            :disabled="btnEliminarReasignacionFC"
                            @click="eliminarReasignacionFC(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de fuente cooperante
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->

                <!-- inicio: total de la secion -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Aumentado</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">
                        {{
                          parseFloat(
                            aumentoTotalReasignacionFC
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!--fin:: total de la seccion -->

                <!-- inicio: total de la secion -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Reducido</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">
                        {{
                          parseFloat(
                            disminucionTotalReasignacionFC
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!--fin:: total de la seccion -->
              </v-row>

              <!-- <v-row v-if="totalSubDivisionRegistradaCooperante != totalDatosFinancierosFC" class="mt-2">
                              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                  <v-alert
                                      outlined
                                      type="error"
                                      dense
                                      >
                                          No se ha distribuido el monto total de los datos financieros registrados en la subdivisión de la fuente cooperante
                                      </v-alert>
                                  
                              </v-col>
  
                          </v-row> -->

              <v-row v-if="alertaReasignacionesFC">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert
                    outlined
                    type="warning"
                    color="orange darken-1"
                    dense
                  >
                    Los montos aumentados no coinciden con los reducidos en la
                    reasignación, por favor verificar la información.
                  </v-alert>
                </v-col>
              </v-row>

              <v-row class="mt-4">
                <v-col cols="12" md="12" sm="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row class="mt-4">
                <v-col cols="12" md="12" sm="12" class="pb-2">
                  <v-subheader class="text-h6 black--text pb-0">
                    Subdivisión del monto suscrito de la contrapartida y otros
                    aportes
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row v-if="!contraPartidaRegistrada && !otrosRegistrado">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert
                    outlined
                    type="warning"
                    color="orange darken-1"
                    dense
                  >
                    No existen datos financieros para la contrapartida y otros
                    aportes registrados.
                  </v-alert>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formDatosFinancieros"
                v-on:submit.prevent="registrarReasignacionCP"
                v-model="validFormReasignacionCP"
              >
                <v-row
                  v-if="contraPartidaRegistrada || otrosRegistrado"
                  class="mt-4"
                >
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <p class="text-subtitle-1 mb-0">Operación</p>
                    <v-radio-group class="mt-0" v-model="accionCP" row>
                      <v-radio
                        label="Aumentar monto (+)"
                        color="light-blue darken-2"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="Disminuir monto (-)"
                        color="light-blue darken-2"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-select
                      v-model="datosReasignacionCP.contrapartidaActividadId"
                      :items="subDivisionesCP"
                      :loading="false"
                      dense
                      filled
                      class="required"
                      label="Subdivisión"
                      item-text="nombreActividad"
                      item-value="id"
                      :no-data-text="
                        subDivisionesCP != null
                          ? 'Selecciona una subdivisión'
                          : 'No se han encontrado subdivisiones'
                      "
                      menu-props="auto"
                      :rules="[selectRequired('Subdivisión')]"
                    >
                      <template v-slot:selection="{ item }" class="pt-2">
                        <v-list-item
                          v-on="on"
                          v-bind="attrs"
                          class="pl-0"
                          style="min-height: 40px !important;"
                        >
                          <v-list-item-content
                            style="padding: 2px 0 !important;"
                          >
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-5 pb-1 pl-0">
                                    <span>{{
                                      item.nombreActividad +
                                        " | " +
                                        item.descripcion
                                    }}</span>
                                  </v-col>
                                  <!--  <v-col cols="12" class="pt-1 pb-1">
                                                                  <span>{{ item.descripcion }}</span>
                                                              </v-col> -->
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                      <v-chip text-color="white" color="blue-grey darken-1" small>
                                                          {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 })  }}
                                                      </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <!--  {{ item.nombreActividad + ' | ' + item.descripcion  + ' | ' + parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 }) }} -->
                      </template>

                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <v-col cols="12">
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.nombreActividad }}</span>
                                  </v-col>
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <span>{{ item.descripcion }}</span>
                                  </v-col>
                                </v-col>
                                <!-- <v-spacer></v-spacer>
                                                              <v-chip text-color="white" color="blue-grey darken-1" small>
                                                                  {{ parseFloat(item.monto).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ',  maximumFractionDigits: 10 })  }}
                                                              </v-chip> -->
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      filled
                      autocomplete="off"
                      class="required"
                      color="blue-grey lighten-2"
                      :suffix="accionCP == '1' ? `(+) GTQ` : `(-) GTQ`"
                      v-model="montoModificadoContrapartida"
                      label="Monto"
                      :rules="[
                        required('monto'),
                        decimals10('monto'),
                        maxLength('monto', 30),
                      ]"
                      maxlength="30"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-btn
                      color="light-blue-502"
                      class="mb-2 float-right mt-2 light-blue-502 white--text"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormReasignacionCP"
                      :loading="btnRegistroReasignacionCP"
                    >
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="contraPartidaRegistrada || otrosRegistrado">
                <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA-->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    dense
                    class="elevation-1"
                    :headers="headersReasignacionesCP"
                    :items="reasignacionesCP"
                    :loading="tableLoadingReasignacionesCP"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}',
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.nombreActividad }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td>
                          {{ item.tipoAccion === 1 ? `Aumentar` : `Reducir` }}
                        </td>
                        <td class="text-right">
                          {{
                            parseFloat(
                              item.montoModificado
                            ).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ",
                              maximumFractionDigits: 10,
                            })
                          }}
                        </td>
                        <td>
                          <v-btn
                            v-if="!enmiendaValidada"
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            :disabled="btnEliminarReasignacionCP"
                            color="blue-grey lighten-5"
                            @click="eliminarReasignacionCP(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de contrapartida
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA -->

                <!-- inicio: total de la secion -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Aumentado</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">
                        {{
                          parseFloat(
                            aumentoTotalReasignacionCP
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!--fin:: total de la seccion -->

                <!-- inicio: total de la secion -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title class="text-h6"
                        >Total Monto Reducido</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-h6">
                        {{
                          parseFloat(
                            disminucionTotalReasignacionCP
                          ).toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ",
                            maximumFractionDigits: 10,
                          })
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!--fin:: total de la seccion -->
              </v-row>

              <!-- <v-row v-if="totalSubDivisionRegistradaContrapartida != totalContrapartidaOtros" class="mt-2">
                              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                  <v-alert
                                      outlined
                                      type="error"
                                      dense
                                      >
                                          No se ha distribuido el monto total de los datos financieros registrados en la subdivisión de la contrapartida y otros aportes
                                      </v-alert>
                                  
                              </v-col>
  
                          </v-row> -->

              <v-row v-if="alertaReasignacionesCP">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-alert
                    outlined
                    type="warning"
                    color="orange darken-1"
                    dense
                  >
                    Los montos aumentados no coinciden con los reducidos en la
                    reasignación, por favor verificar la información.
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            outlined
            class="mt-4"
            v-if="enmiendaRegistrada || enmiendaValidada"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="card-label text-h6 font-weight-bolder text-dark">
                    Documento de respaldo </span
                  ><br />
                  <span
                    class="gray--text mt-3 font-weight-bold text-subtitle-1"
                  >
                    Debe cargar el documento de respaldo para que la enmienda
                    sea aplicada
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-2 pb-2"></v-divider>
                </v-col>
              </v-row>

              <v-form
                v-if="!enmiendaValidada"
                ref="formDatosFinancieros"
                v-on:submit.prevent="cargarArchivo"
                v-model="validFormValidarEnmienda"
              >
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0  pb-0 mb-0">
                    <v-textarea
                      autocomplete="off"
                      class="required"
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="datosValidacion.observaciones"
                      label="Observaciones"
                      :rules="[
                        required('observaciones'),
                        minLength('observaciones', 5),
                        maxLength('observaciones', 2000),
                      ]"
                      maxlength="2000"
                    >
                    </v-textarea>
                  </v-col>

                  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <template>
                      <v-file-input
                        id="respaldoFile"
                        dense
                        filled
                        placeholder="Seleccionar archivo .pdf"
                        label="Seleccione el documento de respaldo"
                        append-icon="mdi-file-pdf-outline"
                        prepend-icon=""
                        accept="application/pdf,image/jpeg"
                        @change="onFileChange"
                        :rules="[fileRequired('documento de respaldo')]"
                        :show-size="1000"
                        ref="respaldoFile"
                        class="required"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip v-if="index < 2" small label color="primary">
                            {{ text }}
                          </v-chip>
                          <span
                            v-else-if="index === 2"
                            class="
                                                      text-overline
                                                      grey--text
                                                      text--darken-3
                                                      mx-2
                                                  "
                          >
                            +{{ files.length - 2 }} archivo(s)
                          </span>
                        </template>
                      </v-file-input>
                    </template>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-btn
                      color="light-blue-502"
                      class="mb-2 float-right mt-2 light-blue-502 white--text"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormValidarEnmienda"
                      :loading="btnValidarEnmienda"
                    >
                      Guardar datos enmienda
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="enmiendaValidada">
                <v-col cols="12" md="12" sm="12" class="text-center">
                  <v-icon large color="green darken-2">
                    mdi-check-all
                  </v-icon>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="text-center">
                  <p class="text-h6">La enmienda ya se encuentra aplicada!</p>
                </v-col>
              </v-row>

              <v-row v-if="enmiendaValidada">
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-8 text-center">
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 mt-2"
                    @click="
                      descargarArchivo(datosEnmienda.pathDocumentoRespaldo)
                    "
                    :elevation="0"
                  >
                    <v-icon left>mdi-cloud-download</v-icon>
                    Descargar documento de respaldo
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog inactivar/activar enmienda -->
    <v-dialog
      v-model="dialogActualizarEstado"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ textoTituloCambioEstado }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnCambioEstadoLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogActualizarEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                {{ textoCambioEstado }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogActualizarEstado = false"
            :disabled="btnCambioEstadoLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="actualizarEstadoEnmienda()"
            :loading="btnCambioEstadoLoading"
          >
            Sí, continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import {
  OBTENER_DATOS_FINANCIEROS_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS,
} from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

import {
  OBTENER_DATOS_FINANCIEROS_ENMIENDA_CNS /* REGISTRAR_DATO_FINANCIERO_ENMIENDA_CNS,*/,
  OBTENER_SUBDIVISIONES_FC_ENMIENDA_CNS,
  REGISTRAR_SUBDIVISION_FC_ENMIENDA_CNS,
  ELIMINAR_SUBDIVISION_FC_ENMIENDA_CNS,
  OBTENER_SUBDIVISIONES_CP_ENMIENDA_CNS,
  REGISTRAR_SUBDIVISION_CP_ENMIENDA_CNS,
  ELIMINAR_SUBDIVISION_CP_ENMIENDA_CNS,
} from "@/core/services/store/proyectoscns/enmiendas/enmiendamontoscns.module";

import {
  REGISTRAR_EJECUCION_ACTIVIDAD_CNS,
  REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS /* ELIMINAR_EJECUCION_ACTIVIDAD_CNS,*/,
} from /*,ELIMINAR_EJECUCION_CONTRAPARTIDA_CNS*/ "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

import {
  OBTENER_ENMIENDAS_COMPONENTES_CNS,
  REGISTRAR_ENMIENDA_COMPONENTES_CNS,
  OBTENER_ENMIENDA_COMPONENTES_CNS,
  VALIDAR_ENMIENDA_COMPONENTES_CNS,
  OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_FC_CNS,
  REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS,
  ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS,
  OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_CP_CNS,
  REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS,
  ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS,
  ACTUALIZAR_ESTADO_ENMIENDA_COMPONENTES_CNS,
  /* OBTENER_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS,*/
  /* OBTENER_SUBDIVISIONES_CP_ENMIENDA_COMPONENTES_CNS*/
} from "@/core/services/store/proyectoscns/enmiendas/enmiendacomponentescns.module";
import DownloadFile from "@/core/untils/downloadfile.js";
//import { OBTENER_DATOS_FINANCIEROS_CNS} from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

const mascaraQuetzales = createNumberMask({
  prefix: "",
  allowDecimal: true,
  decimalLimit: 10,
  includeThousandsSeparator: false,
  allowNegative: false
});

export default {
  name: "AdminEnmiendasComponentes",
  components: {
    SnackAlert,
    DialogLoader
  },
  data() {
    return {
      validForm: false,
      enmiendaId: 0,
      enmiendaRegistrada: false,
      validFormDatosIniciales: false,
      btnRegistroEnmienda: false,
      tableLoading: false,
      validFormDatosFinancieros: false,
      datosFinancieros: {
        id: 0,
        tiposDatosFinancierosId: 0,
        proyectosCNSId: 0,
        monedasId: 0,
        estadosId: 1,
        montoMonedaOriginal: 0,
        tipoCambio: 0,
        montoTotalQuetzales: 0,
        usuarioCreacion: "admin",
      },
      btnDatosFinancierosLoading: false,
      tableDatosFinancierosLoading: false,
      validFormProyeccionSubDivision: false,
      skeletonLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "Obteniendo información....",
      btnProyeccionLoading: false,
      dialogRegistro: false,
      btnRegistroLoading: false,
      fechaActual: "",
      datosProyecto: {},
      enmiendas: [],
      tiposDatosFinancieros: [],
      monedas: [],
      ddTiposDatosFinancierosLoading: false,
      ddMonedasLoading: false,
      filtro: "",
      parameters: [],
      proyectoId: "",
      codigoProyecto: "",
      items: [],
      estados: [
        { value: null, text: "Todos" },
        { value: 1, text: "En registro" },
        { value: 2, text: "Inactiva" },
        { value: 4, text: "Confirmado" }
      ],
      monedaSeleccionada: {},
      mascaraQuetzales,
      montoMonedaOriginal: "",
      tipoCambio: "",
      datosFinancierosProyecto: [],
      fechaEnmienda: "",
      tiposDatoFinancieroSeleccionado: {},
      datosEnmienda: {
        id: 0,
        proyectosCnsId: 0,
        codigo: "",
        fecha: "",
        tipoAccion: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      tiposOperacionEnmiendas: [],
      estadoSeleccionado: {},
      datosItem: {
        numeroEnmienda: "",
        fechaEnmienda: ""
      },
      subDivisiones: [],
      totalSubDivisionCooperante: 0.0,
      subDivision: {},
      datosSubdivision: {
        enmiendasMontosCnsId: 0,
        ejecucionesPorActividadCnsId: 0,
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1
      },
      accionFC: 1,
      accionCP: 1,
      subDivisionesCP: [],
      totalSubDivisionContraPartida: 0.0,
      datosSubdivisionContrapartida: {
        enmiendasMontosCnsId: 0,
        contrapartidasActividadesId: 0,
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1
      },
      descripcionSubdivision: "",
      proyeccionesSubDivision: [],
      totalDatosFinancieros: "",
      monedaFuenteCooperante: "",
      monedaContraPartida: "",
      contraPartidaRegistrada: false,
      otrosRegistrado: false,
      fuenteCooperanteRegistrada: false,
      monedaOtros: "",
      totalDatosFinancierosFC: "",
      totalDatosFinancierosCP: "",
      monedasFuenteCooperanteId: 0,
      monedasContrapartidaId: 0,
      btnEliminarDisabled: false,
      bntEliminarDistribucionCooperanteDisabled: false,
      btnEliminarDistribucionContrapartidaDisabled: false,

      tableLoadingSubdivisionCooperante: false,
      proyeccionesSubDivisionContrapartida: [],
      tableLoadingSubdivisionContrapartida: false,
      datosFuenteCooperanteRegistrados: 0,
      datosContrapartidaRegistrados: 0,
      totalDatosFinancierosOtros: 0.0,
      totalContrapartidaOtros: 0.0,
      validFormSubdivisionCooperante: false,
      subdivisionesEnmiendaFC: [],
      totalSubDivisionRegistradaCooperante: 0.0,
      montoSubdivisionFc: "",
      btnRegistroEjecucionFuenteCooperanteLoading: false,
      btnEliminarEjecucionFCDisabled: false,

      validFormSubdivisionContrapartida: false,
      btnRegistroEjecucionContrapartidaLoading: false,
      subdivisionesEnmiendaCP: [],
      totalSubDivisionRegistradaContrapartida: 0.0,
      montoEjecutadoContrapartida: "",
      montoSubdivisionCP: "",

      validFormValidarEnmienda: false,
      btnValidarEnmienda: false,
      archivo: [],
      dialogAlertaValidacion: false,
      datosValidacion: {
        id: 0,
        pathDocumentoRespaldo: "",
        observaciones: ""
      },
      enmiendaValidada: false,
      validFormProyeccionSubDivisionContraPartida: false,
      aportaContrapartida: 2,
      aportaContrapartidaItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      btnRegistroEjecucionActividadLoading: false,
      btnRegistroEjecucionActividadContraPartidaLoading: false,
      datosEjecucion: {
        id: 0,
        proyectosCnsId: 0,
        actividadesEjecucionId: 0,
        descripcion: "",
        estadosId: 1,
        enmiendasComponentesCnsId: 0,
        usuarioCreacion: "admin"
      },
      datosEjecucionContraPartida: {
        id: 0,
        proyectosCnsId: 0,
        actividadesEjecucionId: 0,
        descripcion: "",
        estadosId: 1,
        enmiendasComponentesCnsId: 0,
        usuarioCreacion: "admin"
      },
      reasignacionesFC: [],
      tableLoadingReasignacionesFC: false,
      tableLoadingReasignacionesCP: false,
      datosReasignacionFC: {
        id: 0,
        tipoAccion: 0,
        enmiendasComponenteId: 0,
        ejecucionActividadId: 0,
        montoModificado: 0.0,
        estadoId: 1,
        usuarioCreacion: "admin"
      },
      validFormReasignacionFC: false,
      btnRegistroReasignacionFC: false,
      reasignacionesCP: [],
      datosReasignacionCP: {
        id: 0,
        tipoAccion: 0,
        enmiendasComponenteId: 0,
        contrapartidaActividadId: 0,
        montoModificado: 0.0,
        estadoId: 1,
        usuarioCreacion: "admin"
      },
      montoModificadoCooperante: "",
      aumentoTotalReasignacionFC: 0.0,
      aumentoTotalReasignacionCP: 0.0,
      disminucionTotalReasignacionFC: 0.0,
      disminucionTotalReasignacionCP: 0.0,
      alertaReasignacionesFC: false,
      alertaReasignacionesCP: false,
      montoModificadoContrapartida: "",
      validFormReasignacionCP: false,
      btnRegistroReasignacionCP: false,
      btnEliminarReasignacionFC: false,
      btnEliminarReasignacionCP: false,
      totalSubDivisionContrapartida: 0.0,
      dialogActualizarEstado: false,
      textoTituloCambioEstado: "",
      btnCambioEstadoLoading: false,
      textoCambioEstado: "",
      ...validations
    };
  },

  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoSeleccionado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoSeleccionado.value;
    },

    resetItems() {
      this.datosFinancierosProyecto = [];
      this.subdivisionesEnmiendaFC = [];
      this.subdivisionesEnmiendaCP = [];
      this.totalDatosFinancierosOtros = 0.0;
      this.totalContrapartidaOtros = 0.0;
      this.totalDatosFinancierosFC = 0.0;
      this.enmiendaRegistrada = false;
      this.enmiendaValidada = false;
      this.resetItemsDatosFinancieros();
      this.resetItemsSubdivisionFc();
      this.resetItemsSubdivisionCP();
    },

    resetItemsSubdivisionCooperante() {
      this.datosEjecucion = {
        id: 0,
        proyectosCnsId: 0,
        actividadesEjecucionId: 0,
        descripcion: "",
        estadosId: 1,
        enmiendasComponentesCnsId: this.enmiendaId,
        usuarioCreacion: "admin",
      };
    },

    resetItemsSubdivisionContrapartida() {
      this.datosEjecucionContraPartida = {
        id: 0,
        proyectosCnsId: 0,
        actividadesEjecucionId: 0,
        descripcion: "",
        estadosId: 1,
        enmiendasComponentesCnsId: this.enmiendaId,
        usuarioCreacion: "admin"
      };
    },

    resetItemsDatosFinancieros() {
      this.montoMonedaOriginal = "";
      this.tipoCambio = "";
      this.datosFinancieros = {
        id: 0,
        tiposDatosFinancierosId: 0,
        enmiendasMontosCnsId: this.enmiendaId,
        monedasId: 0,
        estadosId: 1,
        montoMonedaOriginal: 0,
        tipoCambio: 0,
        montoTotalQuetzales: 0,
        usuarioCreacion: "admin"
      };
    },

    resetItemsSubdivisionFc() {
      this.montoSubdivisionFc = "";
      this.datosEjecucion.descripcion = "";
      this.datosEjecucion.actividadesEjecucionId = null;
      this.datosSubdivision = {
        enmiendasMontosCnsId: this.enmiendaId,
        ejecucionesPorActividadCnsId: 0,
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1,
        tipoAccion: this.datosEnmienda.tipoAccion
      };
    },

    resetItemsSubdivisionCP() {
      this.montoEjecutadoContrapartida = "";
      this.montoSubdivisionCP = "";
      this.datosSubdivisionContrapartida = {
        enmiendasMontosCnsId: this.enmiendaId,
        contrapartidasActividadesId: 0,
        monto: 0,
        usuarioCreacion: "admin",
        estadosId: 1
      };
    },

    resetItemsReasignacionFC() {
      this.accionFC = "0";
      this.datosReasignacionFC = {
        id: 0,
        enmiendasComponenteId: this.enmiendaId,
        ejecucionActividadId: 0,
        montoModificado: 0.0,
        estadoId: 1,
        usuarioCreacion: "admin"
      };
      this.montoModificadoCooperante = "";
    },

    resetItemsReasignacionCP() {
      this.accionCP = "0";
      this.datosReasignacionCP = {
        id: 0,
        enmiendasComponenteId: this.enmiendaId,
        contrapartidaActividadId: 0,
        montoModificado: 0.0,
        estadoId: 1,
        usuarioCreacion: "admin"
      };
      this.montoModificadoContrapartida = "";
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    //Obtener la información de fechas
    async obtenerDatosProyecto(idProyecto) {
      this.datosProyecto = {};
      this.skeletonLoading = true;
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          seccion: "PlazosProyecto",
          id: idProyecto,
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;

            //this.cuentaCondicionDesembolsoSeleccionada.id = this.datosItem.condicionesDesembolsos;

            this.datosProyecto.fechaSuscripcionPlazos
              ? (this.fechaSuscripcionPlazos = moment(
                  this.datosProyecto.fechaSuscripcionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaSuscripcionPlazos = "");
            this.datosProyecto.fechaInicioPlazos
              ? (this.fechaInicioPlazos = moment(
                  this.datosProyecto.fechaInicioPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaInicioPlazos = "");
            this.datosProyecto.fechaAprobacionPlazos
              ? (this.fechaAprobacionPlazos = moment(
                  this.datosProyecto.fechaAprobacionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaAprobacionPlazos = "");
            this.datosProyecto.fechaFinalizacionFaseEjecucion
              ? (this.fechaFaseEjecucionActual = moment(
                  this.datosProyecto.fechaFinalizacionFaseEjecucion,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaFinalizacionFaseEjecucion = "");
            this.datosProyecto.fechaFinalizacionPlazos
              ? (this.fechaFinalizacionActual = moment(
                  this.datosProyecto.fechaFinalizacionPlazos,
                  "YYYY-MM-DD"
                ).format("DD-MM-YYYY"))
              : (this.fechaFinalizacionPlazos = "");
            this.codigoProyecto = this.datosProyecto.codigo;
            if (this.datosProyecto.enmiendasFechas === 1) {
              this.fechaFaseEjecucionActual = this.datosProyecto.fechaFaseEjecucionNueva;
              this.fechaFinalizacionActual = this.datosProyecto.fechaFinalizacionNueva;
            }
            this.proyectoEncontrado = true;
            this.skeletonLoading = false;

            this.obtenerEnimendas(this.proyectoId);
            //this.skeletonLoading = false;

            //console.log(this.datosProyecto.condicionesDesembolsos)

            //console.log(this.cuentaCondicionDesembolsoSeleccionada.id)
            //this.habilitarCondicionesDesembolsos = true; condicionesDesembolsos
          } else {
            this.proyectoEncontrado = false;
            this.skeletonLoading = false;
            this.tableLoading = false;
          }
        })
        .catch(() => {
          this.datosProyecto = {};
          this.skeletonLoading = false;
        });
    },

    //Obtener las enmiendas registradas
    async obtenerEnimendas(proyectoId) {
      this.enmiendas = [];
      this.tableLoading = true;
      this.$store
        .dispatch(OBTENER_ENMIENDAS_COMPONENTES_CNS, {
          estadoId: 0,
          id: proyectoId,
        })
        .then(res => {
          if (res.status === 200) {
            this.enmiendas = res.data;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.enmiendas = [];
          this.tableLoading = false;
        });
    },

    //Registrar enmienda (datos generales)
    async registrarDatosIniciales() {
      this.btnRegistroEnmienda = true;
      this.datosEnmienda.proyectosCNSId = this.proyectoId;

      await this.$store
        .dispatch(REGISTRAR_ENMIENDA_COMPONENTES_CNS, {
          datos: this.datosEnmienda,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            this.enmiendaId = res.data[0].id;
            this.datosEnmienda.codigo = res.data[0].codigo;
            this.enmiendaRegistrada = true;
            //this.obtenerProyeccionesDesembolsos(this.id);
            //this.resetItemsDesembolsos();
            //this.datosMecanismoEjecucion.mecanismosEjecucionId = 0;
            //this.resetItems();
            //this.dialogRegistro = false;

            this.obtenerEnimendas(this.proyectoId);
            this.obtenerDatosItem(this.enmiendaId, 1);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.enmiendaRegistrada = false;
          }
          this.btnRegistroEnmienda = false;
        })
        .catch(error => {
          this.btnRegistroEnmienda = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Obtener los datos de la enmienda
    //Tipo define si viene de una enmienda recién registrada (1) o viene de la acción actualizar (2)
    async obtenerDatosItem(id, tipo) {
      this.datosEnmienda = {};
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";

      this.$store
        .dispatch(OBTENER_ENMIENDA_COMPONENTES_CNS, { id: id })
        .then(res => {
          if (res.status === 200) {
            this.enmiendaId = id;
            this.datosEnmienda = res.data;
            this.obtenerDatosFinancieros(this.proyectoId).then(() => {
              this.datosFinancieros.enmiendasMontosCnsId = id;
              this.datosSubdivision.enmiendasMontosCnsId = id;

              this.accionFC = 0;
              this.accionCP = 0;

              this.datosEjecucion.enmiendasComponentesCnsId = id;
              this.datosEjecucionContraPartida.enmiendasComponentesCnsId = id;

              this.obtenerEjecucionesPorActividad(this.proyectoId);
              this.obtenerEjecucionesPorActividadContrapartida(this.proyectoId);

              this.obtenerSubdivisionesEnmiendaFC(this.enmiendaId).then(() => {
                this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId).then(
                  () => {
                    this.obtenerReasignacionesFC(this.enmiendaId);
                    this.obtenerReasignacionesCP(this.enmiendaId);

                    if (
                      this.datosEnmienda.estadosId === 3 ||
                      this.datosEnmienda.estadosId === 4
                    ) {
                      this.enmiendaValidada = true;
                    }

                    this.dialogLoaderVisible = false;
                    if (
                      tipo === 2 ||
                      (tipo === 1 && this.dialogRegistro == false)
                    ) {
                      this.dialogRegistro = true;
                    }
                  }
                );
              });
            });
            this.enmiendaRegistrada = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.dialogLoaderVisible = false;
          }
        })
        .catch(() => {
          this.enmiendas = [];
          this.dialogLoaderVisible = false;
        });
    },

    //Agregar proyeccion de la ejecución por componente, objetivo, ind, res, meta
    async agregarProyeccionSubDivision() {
      this.datosEjecucion.proyectosCNSId = this.id;

      this.aportaContrapartida === 1
        ? (this.datosEjecucion.contraPartida = 1)
        : (this.datosEjecucion.contraPartida = 0);
      this.btnRegistroEjecucionActividadLoading = true;

      this.datosEjecucion.aporteFuenteCooperante = 0;
      this.datosEjecucion.proyectosCNSId = this.proyectoId;
      this.datosEjecucion.enmiendasComponentesCnsId = this.enmiendaId;

      await this.$store
        .dispatch(REGISTRAR_EJECUCION_ACTIVIDAD_CNS, {
          datos: this.datosEjecucion,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            // let idEjecucionRegistrado=res.data;
            //Validar que se haya seleccionado si tampien tiene aporte a contrapartida
            if (
              this.aportaContrapartida === 1 &&
              this.contraPartidaRegistrada
            ) {
              this.agregarProyeccionSubDivisionContrapartida(1);
            } else {
              this.btnRegistroEjecucionActividadLoading = false;
              this.obtenerEjecucionesPorActividad(this.proyectoId);
              this.resetItemsSubdivisionFc();
            }
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.btnRegistroEjecucionActividadLoading = false;
          }
        })
        .catch(error => {
          this.btnRegistroEjecucionActividadLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Agregar proyeccion subdivision contrapartida
    async agregarProyeccionSubDivisionContrapartida(registroFuenteCooperante) {
      this.datosEjecucionContraPartida.proyectosCNSId = this.proyectoId;

      let datosRegistroEjecucionContrapartida = {};
      if (registroFuenteCooperante === 1) {
        datosRegistroEjecucionContrapartida.actividadesEjecucionId = this.datosEjecucion.actividadesEjecucionId;
        datosRegistroEjecucionContrapartida.descripcion = this.datosEjecucion.descripcion;
        datosRegistroEjecucionContrapartida.enmiendasComponentesCnsId = this.enmiendaId;
        datosRegistroEjecucionContrapartida.proyectosCNSId = this.proyectoId;
        datosRegistroEjecucionContrapartida.usuarioCreacion = "Admin";
        datosRegistroEjecucionContrapartida.estadosId = 1;
        datosRegistroEjecucionContrapartida.monto = 0;
      } else {
        //Validar que la cantidad que se está registrando no sobrepase lo suscrito
        if (
          parseFloat(this.totalSubDivisionContrapartida) +
            parseFloat(this.datosEjecucionContraPartida.monto) >
          this.totalDatosFinancierosCP
        ) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `La cantidad que está registrando, sobrepasa el monto suscrito para la contrapartida (${this.totalDatosFinancierosCP})`
          );
          return;
        }

        datosRegistroEjecucionContrapartida = this.datosEjecucionContraPartida;
      }

      this.btnRegistroEjecucionActividadContraPartidaLoading = true;

      await this.$store
        .dispatch(REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS, {
          datos: datosRegistroEjecucionContrapartida,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //Validar si el registro viene desde fuente cooperante para obtener las proyecciones de FC
            if (registroFuenteCooperante === 1) {
              this.obtenerEjecucionesPorActividad(this.proyectoId);
            }
            this.obtenerEjecucionesPorActividadContrapartida(this.enmiendaId);
            //this.resetItemsEjecucion();
            this.resetItemsEjecucionContrapartida();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.btnRegistroEjecucionActividadLoading = false;
          }
          this.btnRegistroEjecucionActividadLoading = false;
          this.btnRegistroEjecucionActividadContraPartidaLoading = false;
        })
        .catch(error => {
          this.btnRegistroEjecucionActividadLoading = false;
          this.btnRegistroEjecucionActividadContraPartidaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Obtener las ejecuciones por actividdad de cns
    async obtenerEjecucionesPorActividad(proyectoId) {
      this.tableLoadingSubdivisionCooperante = true;
      this.proyeccionesSubDivision = [];
      //this.totalSubDivisionCooperante=0;
      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CNS, {
          estadoId: 1,
          id: proyectoId,
        })
        .then(res => {
          console.logres;
          if (res.status === 200) {
            this.proyeccionesSubDivision = res.data;
            //this.totalSubDivisionCooperante = this.proyeccionesSubDivision.reduce((a,b) => a + (b['aporteFuenteCooperante'] || 0), 0);

            // console.log(this.totalSubDivisionCooperante)
          }
          this.tableLoadingSubdivisionCooperante = false;
        })
        .catch((err) => {
          console.log(err);
          this.proyeccionesSubDivision = [];
          this.tableLoadingSubdivisionCooperante = false;
        });
    },

    async obtenerEjecucionesPorActividadContrapartida(proyectoId) {
      this.tableLoadingSubdivisionContrapartida = true;
      this.proyeccionesSubDivisionContrapartida = [];
      //this.totalSubDivisionContrapartida = 0;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS, {
          estadoId: 1,
          id: proyectoId,
        })
        .then(res => {
          if (res.status === 200) {
            this.proyeccionesSubDivisionContrapartida = res.data;
            //this.totalSubDivisionContrapartida = this.proyeccionesSubDivisionContrapartida.reduce((a,b) => a + (b['monto'] || 0), 0);
          }
          this.tableLoadingSubdivisionContrapartida = false;
        })
        .catch(() => {
          this.proyeccionesSubDivisionContrapartida = [];
          this.tableLoadingSubdivisionContrapartida = false;
        });
    },

    //Obtener los tipos de operacion para la enmienda
    async obtenerTiposOperacionEnmiendas() {
      this.tiposOperacionEnmiendas = [];
      this.tiposOperacionEnmiendas = [
        { id: 1, tipo: "Ampliar" },
        { id: 2, tipo: "Reducir" },
      ];
    },

    //Obtener tipos de datos financieros
    async obtenerTiposDatosFinancieros() {
      this.tiposDatosFinancieros = [];

      this.ddTiposDatosFinancierosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposDatosFinancieros/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposDatosFinancieros = res.data;
          }
          this.ddTiposDatosFinancierosLoading = false;
        })
        .catch(() => {
          this.tiposDatosFinancieros = [];
          this.ddTiposDatosFinancierosLoading = false;
        });

      //this.agregarDatosDistribucion();
    },

    async obtenerDatosFinancieros(proyectoId) {
      this.tableDatosFinancierosLoading = true;
      this.datosFinancierosProyecto = [];
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            //console.log(res.data)
            this.datosFinancierosProyecto = res.data;
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
              (a, b) => a + (b["montoTotalQuetzales"] || 0),
              0
            );

            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;
            searchObjectFC
              ? (this.datoFinancieroFCRegistrado = searchObjectFC.length)
              : (this.datoFinancieroFCRegistrado = 0);
            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;
            if (searchObjectFC) {
              this.fuenteCooperanteRegistrada = true;
            } else {
              this.fuenteCooperanteRegistrada = false;
            }

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.contraPartidaRegistrada = true;

            } else {
              this.contraPartidaRegistrada = false;
            }

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.monedaOtros = searchObjectOtros.codigoMoneda;
              this.otrosAportesRegistrados = true;

              if (
                searchObjectCP.monedasId != searchObjectOtros.monedasId ||
                searchObjectCP.tipoCambio != searchObjectOtros.tipoCambio
              ) {
                this.alertaDatosFinancierosMonedaCambio = true;
              } else {
                this.alertaDatosFinancierosMonedaCambio = false;
              }
            } else {
              this.otrosAportesRegistrados = false;
            }

            //Sumar fuente cooperante
            this.totalDatosFinancierosFC = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 1
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //console.log(this.totalDatosFinancierosFC)
            //Sumar total contrapartida
            /*this.totalDatosFinancierosCP = this.datosFinancierosProyecto.filter(({
                                                                                    tiposDatosFinancierosId
                                                                                }) => tiposDatosFinancierosId === 2).reduce((a,b) =>  a + (b['montoTotalQuetzales'] || 0), 0);
                    */

            this.totalDatosFinancierosCP = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) =>
                  tiposDatosFinancierosId === 2 || tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalDatosFinancierosOtros = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) =>
                  tiposDatosFinancierosId === 2 || tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);
          }
          this.tableDatosFinancierosLoading = false;
        })
        .catch(() => {
          //console.log(error)
          this.datosFinancierosProyecto = [];
          this.tableDatosFinancierosLoading = false;
        });
    },

    //Obtener tipos de datos financieros
    async obtenerDatosFinancieros2(enmiendaId) {
      /* this.tiposDatosFinancieros = [];
  
              this.ddTiposDatosFinancierosLoading=true;
              
              await this.$store
              .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposDatosFinancieros/all/1'})
              .then(res => {
                  if (res.status === 200) {
                      this.tiposDatosFinancieros = res.data;
  
                  }
                  this.ddTiposDatosFinancierosLoading=false;
              })
              .catch(() => {
                  this.tiposDatosFinancieros = [];
                  this.ddTiposDatosFinancierosLoading=false;
              });*/
      this.ddTiposDatosFinancierosLoading = true;
      this.datosFinancierosProyecto = [];
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_ENMIENDA_CNS, {
          id: enmiendaId,
          estadoId: 1,
        })
        .then(res => {
          if (res.status === 200) {
            this.datosFinancierosProyecto = res.data;
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
              (a, b) => a + (b["montoTotalQuetzales"] || 0),
              0
            );

            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
              this.fuenteCooperanteRegistrada = true;
              this.monedasFuenteCooperanteId = searchObjectFC.monedasId;
            } else {
              this.fuenteCooperanteRegistrada = false;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;

            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.contraPartidaRegistrada = true;
              this.monedasContrapartidaId = searchObjectCP.monedasId;
            } else {
              this.contraPartidaRegistrada = false;
            }

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.monedaOtros = searchObjectOtros.codigoMoneda;
              this.otrosRegistrado = true;
            } else {
              this.otrosRegistrado = false;
            }

            //Sumar fuente cooperante
            this.totalDatosFinancierosFC = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 1
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //console.log(this.totalDatosFinancierosFC)
            //Sumar total contrapartida
            this.totalDatosFinancierosCP = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 2
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //Sumar total otros
            this.totalDatosFinancierosOtros = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalContrapartidaOtros =
              this.totalDatosFinancierosCP + this.totalDatosFinancierosOtros;
          }
          this.ddTiposDatosFinancierosLoading = false;
        })
        .catch(() => {
          //console.logerror
          this.datosFinancierosProyecto = [];
          this.ddTiposDatosFinancierosLoading = false;
        });

      //this.agregarDatosDistribucion();
    },

    /**
     * Obtener las subdivisiones asignadas a la enmienda fuente cooperante por proyecto y año
     */
    async obtenerSubdivisionesEnmiendaFC(enmiendaId) {
      this.subdivisionesEnmiendaFC = [];
      this.totalSubDivisionRegistradaCooperante = 0.0;
      await this.$store
        .dispatch(OBTENER_SUBDIVISIONES_FC_ENMIENDA_CNS, {
          estadoId: 1,
          id: enmiendaId,
        })
        .then(res => {
          if (res.status === 200) {
            this.subdivisionesEnmiendaFC = res.data;
            this.totalSubDivisionRegistradaCooperante = this.subdivisionesEnmiendaFC.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subdivisionesEnmiendaFC = [];
        });
    },

    /**
     * Obtener las subdivisiones asignadas a la enmienda de contrapartida por proyecto y año
     */
    async obtenerSubdivisionesEnmiendaCP(enmiendaId) {
      this.subdivisionesEnmiendaCP = [];
      this.totalSubDivisionRegistradaContrapartida = 0.0;
      await this.$store
        .dispatch(OBTENER_SUBDIVISIONES_CP_ENMIENDA_CNS, {
          estadoId: 1,
          id: enmiendaId,
        })
        .then(res => {
          if (res.status === 200) {
            this.subdivisionesEnmiendaCP = res.data;
            this.totalSubDivisionRegistradaContrapartida = this.subdivisionesEnmiendaCP.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subdivisionesEnmiendaCP = [];
        });
    },

    //Obtener monedas
    async obtenerMonedas() {
      this.monedas = [];

      this.ddMonedasLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Moneda/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.monedas = res.data;
          }
          this.ddMonedasLoading = false;
        })
        .catch(() => {
          this.monedas = [];
          this.ddMonedasLoading = false;
        });
    },

    //Obtener subdivisiones (Componente, objetivo, indicador, resultado, meta )
    //Obtener subdivisiones/actividades (Componente, objetivo, indicador, resultado, meta ) fuente cooperante
    /* async obtenerSubDivisiones(proyectoId){
          
              this.subDivisiones = [];
              
              await this.$store
                  .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `EjecucionesPorActividadCNS/all/1/${proyectoId}`})
                  .then(res => {
                  if (res.status === 200) {
                      this.subDivisiones = res.data;
                      this.totalSubDivisionCooperante = this.subDivisiones.reduce((a,b) => a + (b['monto'] || 0), 0);
                  }
                  })
                  .catch(() => {
                  
                      this.subDivisiones = [];
                  });
          }, */

    //Obtener subdivisiones (Componente, objetivo, indicador, resultado, meta )
    async obtenerSubDivisiones() {
      this.subDivisiones = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "ActividadesEjecucion/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisiones = res.data;
          }
          //this.ddTiposDatosFinancierosLoading=false;
        })
        .catch(() => {
          this.subDivisiones = [];
          //this.ddTiposDatosFinancierosLoading=false;
        });
    },

    //Obtener subdivisiones / actividades (Componente, objetivo, indicador, resultado, meta ) contrapartida
    async obtenerSubDivisionesContraPartida(proyectoId) {
      this.subDivisionesCP = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ContrapartidasActividades/all/1/${proyectoId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisionesCP = res.data;
            this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subDivisionesCP = [];
        });
    },

    configurarMoneda() {
      this.monedaSeleccionada.id = this.tiposDatoFinancieroSeleccionado.monedasId;
      this.monedaSeleccionada.codigoMoneda = this.tiposDatoFinancieroSeleccionado.codigoMoneda;
      this.monedaSeleccionada.nombreMoneda = this.tiposDatoFinancieroSeleccionado.nombreMoneda;
    },
    //Obtener las enmiendas registradas
    async obtenerItems() {},

    modalNuevo() {
      this.dialogRegistro = true;
    },

    //Registrar una enmienda
    async registrarItem() {},

    ///Fuente cooperante
    //Registrar ejecucion fuente cooperante
    async registrarEjecucionCooperante() {
      //Validar la cantidad de desembolsos recibidos no sea mayor a lo suscrito por la fuente cooperante
      if (
        parseFloat(this.datosSubdivision.monto) +
          parseFloat(this.totalEjecucionFC) >
        parseFloat(this.totalDatosFinancierosFC)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar 
                                                                          ${parseFloat(
                                                                            this
                                                                              .datosSubdivision
                                                                              .monto
                                                                          ).toLocaleString(
                                                                            "es-GT",
                                                                            {
                                                                              style:
                                                                                "currency",
                                                                              currency:
                                                                                "GTQ",
                                                                              maximumFractionDigits: 10,
                                                                            }
                                                                          )} +  ${parseFloat(
            this.totalEjecucionFC
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ",
            maximumFractionDigits: 10,
          })}  
                                                                          sobrepasa al monto suscrito por la fuente cooperante ${parseFloat(
                                                                            this
                                                                              .totalDatosFinancierosFC
                                                                          ).toLocaleString(
                                                                            "es-GT",
                                                                            {
                                                                              style:
                                                                                "currency",
                                                                              currency:
                                                                                "GTQ",
                                                                              maximumFractionDigits: 10,
                                                                            }
                                                                          )}`
        );

        return;
      }

      this.datosSubdivision.tipoAccion = this.datosEnmienda.tipoAccion;

      this.datosSubdivision.monto = parseFloat(this.montoSubdivisionFc);
      //this.datosSubdivision.iafcnsId = this.iaffId;
      this.btnRegistroEjecucionFuenteCooperanteLoading = true;
      //this.datosSubdivision.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_SUBDIVISION_FC_ENMIENDA_CNS, {
          datos: this.datosSubdivision,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSubdivisionesEnmiendaFC(this.enmiendaId);
            //this.dialogRegistro = false;
            this.resetItemsSubdivisionFc();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionFuenteCooperanteLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionFuenteCooperanteLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución de fuente cooperante
     */

    async eliminarEjecucionCooperante(id) {
      this.dialogLoaderVisible = true;
      this.btnEliminarEjecucionFCDisabled = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_SUBDIVISION_FC_ENMIENDA_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSubdivisionesEnmiendaFC(this.enmiendaId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionFCDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionFCDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    ///Contrapartida
    //Registrar ejecucion contrapartida
    async registrarEjecucionContrapartida() {
      //Validar la cantidad de desembolsos recibidos no sea mayor a lo suscrito por la fuente cooperante
      if (
        parseFloat(this.montoEjecutadoContrapartida) +
          parseFloat(this.totalSubDivisionRegistradaContrapartida) >
        parseFloat(this.totalContraPartidaOtros)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar 
                                                                         ${parseFloat(
                                                                           this
                                                                             .montoEjecutadoContrapartida
                                                                         ).toLocaleString(
                                                                           "es-GT",
                                                                           {
                                                                             style:
                                                                               "currency",
                                                                             currency:
                                                                               "GTQ",
                                                                             maximumFractionDigits: 10,
                                                                           }
                                                                         )} +  ${parseFloat(
            this.totalEjecucionCP
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ",
            maximumFractionDigits: 10,
          })}  
                                                                          sobrepasa al monto suscrito por la contra partida y otros aportes ${parseFloat(
                                                                            this
                                                                              .totalContraPartidaOtros
                                                                          ).toLocaleString(
                                                                            "es-GT",
                                                                            {
                                                                              style:
                                                                                "currency",
                                                                              currency:
                                                                                "GTQ",
                                                                              maximumFractionDigits: 10,
                                                                            }
                                                                          )}`
        );

        return;
      }

      //this.datosEjecucionContrapartida.fechaEjecucion = moment(this.fechaEjecucionCP, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosSubdivisionContrapartida.montoEjecutado = parseFloat(
        this.montoEjecutadoContrapartida
      );

      this.datosSubdivisionContrapartida.enmiendasMontosCnsId = this.enmiendaId;
      this.datosSubdivisionContrapartida.tipoAccion = this.datosEnmienda.tipoAccion;

      this.datosSubdivisionContrapartida.monto = parseFloat(
        this.montoEjecutadoContrapartida
      );

      this.btnRegistroEjecucionContrapartidaLoading = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_SUBDIVISION_CP_ENMIENDA_CNS, {
          datos: this.datosSubdivisionContrapartida,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId);
            //this.dialogRegistro = false;
            this.resetItemsSubdivisionCP();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionContrapartidaLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionContrapartidaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución de contrapartida
     */
    async eliminarDistribucionContrapartida(id) {
      this.btnEliminarEjecucionCPDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_SUBDIVISION_CP_ENMIENDA_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    /*Obtener las reasignaciones de la fuente cooperante*/
    async obtenerReasignacionesFC(enmiendaId) {
      this.reasignacionesFC = [];
      this.tableLoadingReasignacionesFC = true;
      this.aumentoTotalReasignacionFC = 0.0;
      this.disminucionTotalReasignacionFC = 0.0;

      await this.$store
        .dispatch(OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_FC_CNS, {
          id: enmiendaId,
          estadoId: 1,
        })
        .then(res => {
          if (res.status === 200) {
            this.reasignacionesFC = res.data;
            this.aumentoTotalReasignacionFC = this.reasignacionesFC
              .filter(({ tipoAccion }) => tipoAccion === 1)
              .reduce((a, b) => a + (b["montoModificado"] || 0), 0);
            this.disminucionTotalReasignacionFC = (this.reasignacionesFC
              .filter(({ tipoAccion }) => tipoAccion === 2)
              .reduce((a, b) => a + (b["montoModificado"] || 0), 0) * -1);

            if (
              parseFloat(this.aumentoTotalReasignacionFC) !=
              parseFloat(this.disminucionTotalReasignacionFC)
            ) {
              this.alertaReasignacionesFC = true;
            } else {
              this.alertaReasignacionesFC = false;
            }

            //this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce((a,b) => a + (b['monto'] || 0), 0);
          }
          this.tableLoadingReasignacionesFC = false;
        })
        .catch(() => {
          this.aumentoTotalReasignacionFC = 0.0;
          this.disminucionTotalReasignacionFC = 0.0;
          this.tableLoadingReasignacionesFC = false;
          this.reasignacionesFC = [];
        });
    },

    /*Registrar reasignacion de la fuente cooperante*/
    async registrarReasignacionFC() {
      if (this.accionFC < 1 || this.accionFC > 2) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `No ha seleccionado una operación (Reducir o aumentar monto)`
        );
        return;
      }

      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;
      this.btnRegistroReasignacionFC = true;
      this.datosReasignacionFC.enmiendaCompoenteId = this.enmiendaId;
      this.datosReasignacionFC.tipoAccion = parseInt(this.accionFC);
      this.datosReasignacionFC.montoModificado = parseFloat(
        this.montoModificadoCooperante
      );
      this.$store
        .dispatch(REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS, {
          datos: this.datosReasignacionFC,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerReasignacionesFC(this.enmiendaId);
            //this.dialogRegistro = false;
            this.resetItemsReasignacionFC();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroReasignacionFC = false;
        })
        .catch(() => {
          this.btnRegistroReasignacionFC = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar reasignacion de la fuente cooperante
     */
    async eliminarReasignacionFC(id) {
      this.btnEliminarReasignacionFC = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerReasignacionesFC(this.enmiendaId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarReasignacionFC = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarReasignacionFC = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    /*Obtener las reasignaciones de la contrapartida*/
    async obtenerReasignacionesCP(enmiendaId) {
      this.reasignacionesCP = [];
      this.aumentoTotalReasignacionCP = 0.0;
      this.disminucionTotalReasignacionCP = 0.0;
      this.tableLoadingReasignacionesCP = true;
      await this.$store
        .dispatch(OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_CP_CNS, {
          id: enmiendaId,
          estadoId: 1,
        })
        .then(res => {
          if (res.status === 200) {
            this.reasignacionesCP = res.data;
            this.aumentoTotalReasignacionCP = this.reasignacionesCP
              .filter(({ tipoAccion }) => tipoAccion === 1)
              .reduce((a, b) => a + (b["montoModificado"] || 0), 0);
            this.disminucionTotalReasignacionCP = ((this.reasignacionesCP
              .filter(({ tipoAccion }) => tipoAccion === 2)
              .reduce((a, b) => a + (b["montoModificado"] || 0), 0)) * -1);

            if (
              parseFloat(this.aumentoTotalReasignacionCP) !=
              parseFloat(this.disminucionTotalReasignacionCP)
            ) {
              this.alertaReasignacionesCP = true;
            } else {
              this.alertaReasignacionesCP = false;
            }
            //this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce((a,b) => a + (b['monto'] || 0), 0);
          }
          this.tableLoadingReasignacionesCP = false;
        })
        .catch(() => {
          this.aumentoTotalReasignacionCP = 0.0;
          this.disminucionTotalReasignacionCP = 0.0;
          this.tableLoadingReasignacionesCP = false;
          this.reasignacionesCP = [];
        });
    },

    /*Registrar reasignacion de la contrapartida*/
    async registrarReasignacionCP() {
      if (this.accionCP < 1 || this.accionCP > 2) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `No ha seleccionado una operación (Reducir o aumentar monto)`
        );
        return;
      }

      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;
      this.btnRegistroReasignacionCP = false;
      this.datosReasignacionCP.enmiendaCompoenteId = this.enmiendaId;
      this.datosReasignacionCP.montoModificado = parseFloat(
        this.montoModificadoContrapartida
      );

      this.datosReasignacionCP.tipoAccion = parseInt(this.accionCP);
      this.$store
        .dispatch(REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS, {
          datos: this.datosReasignacionCP,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerReasignacionesCP(this.enmiendaId);
            //this.dialogRegistro = false;
            this.resetItemsReasignacionCP();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroReasignacionCP = false;
        })
        .catch(() => {
          this.btnRegistroReasignacionCP = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar reasignacion de la contrapartida
     */
    async eliminarReasignacionCP(id) {
      this.btnEliminarReasignacionCP = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerReasignacionesCP(this.enmiendaId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarReasignacionCP = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarReasignacionCP = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Agregar subdivion
    /*async agregarProyeccionSubDivision() {
              this.proyeccionesSubDivision.push({
                  nombreSubdivision: this.subDivision.nombreSubDivision,
                  descripcionSubdivision: this.descripcionSubdivision,
                  aporteFuenteCooperante: this.aporteFuenteCooperante
              });
  
              if(parseFloat(this.aporteContrapartida) > 0){
                  this.proyeccionesSubDivisionContrapartida.push({
                      nombreSubdivision: this.subDivision.nombreSubDivision,
                      descripcionSubdivision: this.descripcionSubdivision,
                      aporteFuenteCooperante: this.aporteContrapartida
                  });
              }
  
  
              this.subDivision = {};
              this.descripcionSubdivision = '';
              this.aporteFuenteCooperante = '';
              this.aporteContrapartida = '';
          },*/

    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnValidarEnmienda = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cns\\documentos\\enmiendas\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            this.datosValidacion.pathDocumentoRespaldo = res.pathArchivo;
            this.datosEnmienda.pathDocumentoRespaldo = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.validarEmienda();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnValidarEnmienda = false;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnValidarEnmienda = false;
        });
    },

    validarEmienda() {
      this.datosValidacion.id = this.enmiendaId;

      this.$store
        .dispatch(VALIDAR_ENMIENDA_COMPONENTES_CNS, this.datosValidacion)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.enmiendaValidada = true;
            this.obtenerEnimendas(this.proyectoId);
            //this.obtenerSubdivisionesEnmiendaCP(this.enmiendaId);
            //this.dialogRegistro = false;
            //this.resetItemsSubdivisionCP();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnValidarEnmienda = false;
        })
        .catch(() => {
          this.btnValidarEnmienda = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    mostrarModalInactivar(id, nuevoEstadoId) {
      if (nuevoEstadoId === 2) {
        this.textoTituloCambioEstado = "Inactivar enmienda";
        this.textoCambioEstado =
          "¿Está seguro de inactivar la enmienda seleccionada?";
      }
      if (nuevoEstadoId === 1) {
        this.textoTituloCambioEstado = "Activar enmienda";
        this.textoCambioEstado =
          "¿Está seguro de activar nuevamente la enmienda seleccionada?";
      }
      this.idSeleccionado = id;
      this.estadoActualId = nuevoEstadoId;
      //Si el estado actual es confirmado, pasarlo a inactivo
      this.nuevoEstadoId = nuevoEstadoId;
      this.dialogActualizarEstado = true;
    },

    async actualizarEstadoEnmienda() {
      this.btnCambioEstadoLoading = true;
      await this.$store
        .dispatch(ACTUALIZAR_ESTADO_ENMIENDA_COMPONENTES_CNS, {
          id: this.idSeleccionado,
          estadoId: this.nuevoEstadoId,
        })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogActualizarEstado = false;
            this.obtenerEnimendas(this.proyectoId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Error!",
              res.message
            );
          }
          this.btnCambioEstadoLoading = false;
        })
        .catch(error => {
          //console.logerror
          this.dialogActualizarEstado = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Ha ocurrido un error al intentar realizar la acción especificada, por favor, póngase en contacto con un administrador del sistema. REF:" +
              error
          );
          this.btnCambioEstadoLoading = false;
        });
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      //console.log(path)
      /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */
      DownloadFile.download(path);
    }
  },

  created() {
    let currentDate = new Date().toJSON().slice(0, 10);
    this.fechaActual = moment(currentDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    this.parameters = this.$route.params;
    this.proyectoId = this.$CryptoJS.AES.decrypt(
      this.parameters.proyectoId,
      "KEYADMINCNS2022"
    ).toString(this.CryptoJS.enc.Utf8);
    //this.codigoProyecto = this.parameters.codigoProyecto;
    this.obtenerSubDivisiones(this.proyectoId);
    this.obtenerDatosProyecto(this.proyectoId);

    this.obtenerTiposOperacionEnmiendas();
    this.obtenerTiposDatosFinancieros(this.proyectoId);
    this.obtenerSubDivisionesContraPartida(this.proyectoId);
    this.obtenerMonedas();
    //this.obtenerSubDivisiones();
    //Generar la fecha

    var today = new Date();

    var date =
      +today.getDate() +
      "/" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "/" +
      today.getFullYear();
    this.datosItem.fechaEnmienda = date;
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos CNS" },
      { title: "Enmiendas" },
    ]);
  },

  computed: {
    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      },
    },
    headers() {
      return [
        {
          text: "Orden",
          align: "start",
          sortable: true,
          value: "orden",
        },
        {
          text: "Procedencia",
          align: "start",
          sortable: true,
          value: "procedencia",
        },

        {
          text: "Código de enmienda",
          align: "start",
          sortable: true,
          value: "numeroEnmienda",
        },
        {
          text: "Fecha enmienda",
          align: "start",
          sortable: true,
          value: "fechaCreacion",
        },
        /*{
                      text: "Tipo de operación",
                      align: "start",
                      sortable: true,
                      value: "accion"
                  },*/
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadosId",
          filter: this.filtroEstado,
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },

    headersDatosFinancieros() {
      return [
        {
          text: "Datos Financieros",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Moneda",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Monto Original",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Monto total en quetzales",
          align: "start",
          sortable: true,
          value: "montoTotalQuetzales",
        },
        /*  {
                  text: "Acción",
                  align: "start",
                  sortable: true,
                  value: "nombreMoneda"
              }, */
      ];
    },
    headersSubDivisionesCooperante() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        /*  {
                      text: "Monto",
                      align: "center",
                      sortable: true,
                      value: "monto"
                  },
                  {
                      text: "Accion",
                      align: "start",
                      sortable: true,
                      value: "nombreMoneda"
                  }, */
      ];
    },

    headersSubDivisionesContrapartida() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          //divider: true,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        /*  {
                      text: "Monto",
                      align: "center",
                      sortable: true,
                      //divider: true,
                      value: "nombreMoneda"
                  },
                  {
                      text: "Accion",
                      align: "start",
                      sortable: true,
                      //divider: true,
                      value: "nombreMoneda"
                  }, */
      ];
    },

    headersReasignacionesFC() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          //divider: true,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Operacion Aplicada",
          align: "center",
          sortable: true,
          //divider: true,
          value: "tipoAccion",
        },
        {
          text: "Monto",
          align: "center",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersReasignacionesCP() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          //divider: true,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Operacion Aplicada",
          align: "center",
          sortable: true,
          //divider: true,
          value: "tipoAccion",
        },
        {
          text: "Monto",
          align: "center",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
      ];
    },
  },

  watch: {
    montoMonedaOriginal: function() {
      this.datosFinancieros.montoTotalQuetzales =
        this.montoMonedaOriginal * this.tipoCambio;
    },

    tipoCambio: function() {
      this.datosFinancieros.montoTotalQuetzales =
        this.montoMonedaOriginal * this.tipoCambio;
    },
  },
};
</script>
